define("ui/components/modal-edit-apikey/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-apikey/template", "moment", "jquery"], function (_exports, _newOrEdit, _modalBase, _template, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    endpointService: Ember.inject.service('endpoint'),
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    model: null,
    clone: null,
    justCreated: false,
    expire: 'never',
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    displayEndpoint: Ember.computed.alias('endpointService.api.display.current'),
    linkEndpoint: Ember.computed.alias('endpointService.api.auth.current'),
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.setProperties(this, {
        clone: Ember.get(this, 'originalModel').clone(),
        model: Ember.get(this, 'originalModel').clone(),
        justCreated: false
      });
      this.expireChanged();
    },
    didInsertElement: function didInsertElement() {
      setTimeout(function () {
        (0, _jquery.default)('TEXTAREA')[0].focus();
      }, 250);
    },
    expireChanged: Ember.observer('expire', function () {
      var now = (0, _moment.default)();
      var expire = now.clone();

      if (Ember.get(this, 'expire')) {
        expire = expire.add(1, Ember.get(this, 'expire'));
      }

      Ember.set(this, 'model.ttl', expire.diff(now));
    }),
    editing: Ember.computed('clone.id', function () {
      return !!Ember.get(this, 'clone.id');
    }),
    displayPassword: Ember.computed('clone.token', 'clone.name', function () {
      var prefix = Ember.get(this, 'clone.name');
      var token = Ember.get(this, 'clone.token');

      if (!token || !prefix) {
        return null;
      }

      var parts = token.split(':');

      if (parts.length === 2 && parts[0] === prefix) {
        return parts[1];
      }

      return null;
    }),
    allClusters: Ember.computed('scope.allClusters.@each.{id}', function () {
      var allClusters = Ember.get(this, 'scope.allClusters');
      return allClusters.map(function (c) {
        return {
          label: "".concat(Ember.get(c, 'displayName'), " ( ").concat(Ember.get(c, 'id'), " )"),
          value: Ember.get(c, 'id')
        };
      }).sortBy('displayName');
    }),
    doneSaving: function doneSaving(neu) {
      if (Ember.get(this, 'editing')) {
        this.send('cancel');
      } else {
        Ember.setProperties(this, {
          justCreated: true,
          clone: neu.clone()
        });
      }
    }
  });

  _exports.default = _default;
});