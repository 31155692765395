define("ui/ie/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4uDbqeB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sad-ie\"],[8],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[7,\"h3s\",true],[8],[0,\"Sorry, IE and Edge are not supported.\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/ie/template.hbs"
    }
  });

  _exports.default = _default;
});