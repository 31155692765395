define("ui/ie/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    bootstrap: Ember.on('init', function () {
      Ember.run.schedule('afterRender', this, function () {
        (0, _jquery.default)('#loading-overlay').hide();
        (0, _jquery.default)('#loading-underlay').hide();
      });
    })
  });

  _exports.default = _default;
});