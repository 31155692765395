define("ui/components/notifier/modal-new-edit/component", ["exports", "ui/mixins/modal-base", "ui/components/notifier/modal-new-edit/template", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _modalBase, _template, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TYPES = [{
    type: 'slack',
    label: 'notifierPage.notifierTypes.slack',
    css: 'slack',
    disabled: false
  }, {
    type: 'email',
    label: 'notifierPage.notifierTypes.email',
    css: 'email',
    disabled: false
  }, {
    type: 'pagerduty',
    label: 'notifierPage.notifierTypes.pagerduty',
    css: 'pagerduty',
    disabled: false
  }, {
    type: 'webhook',
    label: 'notifierPage.notifierTypes.webhook',
    css: 'webhook',
    disabled: false
  }, {
    type: 'wechat',
    label: 'notifierPage.notifierTypes.wechat',
    css: 'wechat',
    disabled: false
  }];
  var RECIPIENT_TYPES = [{
    label: 'notifierPage.wechat.recipientType.party',
    value: 'party'
  }, {
    label: 'notifierPage.wechat.recipientType.tag',
    value: 'tag'
  }, {
    label: 'notifierPage.wechat.recipientType.user',
    value: 'user'
  }];

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service('scope'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'large-modal'],
    modelMap: null,
    errors: null,
    testing: false,
    testOk: true,
    recipientTypes: RECIPIENT_TYPES,
    cluster: Ember.computed.alias('scope.currentCluster'),
    currentType: Ember.computed.alias('modalService.modalOpts.currentType'),
    model: Ember.computed.alias('modalService.modalOpts.model'),
    mode: Ember.computed.reads('modalService.modalOpts.mode'),
    init: function init() {
      this._super.apply(this, arguments);

      var mode = Ember.get(this, 'mode');

      if (mode === 'edit' || mode === 'clone') {
        var t = Ember.get(this, 'currentType');
        this.set('types', TYPES.filterBy('type', t));
      } else if (mode === 'add') {
        Ember.set(this, 'modelMap', {});
        this.setModel(Ember.get(this, 'currentType'));
        this.set('types', TYPES);
      }
    },
    actions: {
      switchType: function switchType(type) {
        this.set('currentType', type);
        this.setModel(type);
      },
      test: function test() {
        var _this = this;

        if (Ember.get(this, 'testing') || Ember.get(this, 'tested')) {
          return Ember.RSVP.resolve();
        }

        var ok = this.validate();

        if (!ok) {
          return Ember.RSVP.resolve();
        }

        var data = Ember.get(this, 'model').serialize();
        var gs = Ember.get(this, 'globalStore');
        Ember.set(this, 'testing', true); // TODO: better way to do collection actions

        return gs.rawRequest({
          url: 'notifiers?action=send',
          method: 'POST',
          data: data
        }).then(function () {
          _this.setProperties({
            testOk: true,
            tested: true,
            testing: false,
            errors: null
          });

          setTimeout(function () {
            _this.setProperties({
              tested: false
            });
          }, 3000);
        }).catch(function (xhr) {
          _this.setProperties({
            testOk: false,
            tested: true,
            testing: false,
            errors: [xhr.body.message || xhr.body.code]
          });

          setTimeout(function () {
            _this.setProperties({
              tested: false
            });
          }, 3000);
        });
      }
    },
    currentTypeChanged: Ember.observer('currentType', function () {
      Ember.set(this, 'errors', null);
    }),
    addBtnLabel: Ember.computed('mode', function () {
      var mode = Ember.get(this, 'mode');

      if (mode === 'edit') {
        return 'generic.save';
      } else if (mode === 'clone') {
        return 'notifierPage.clone';
      } else if (mode === 'add') {
        return 'generic.add';
      }
    }),
    isSelectType: Ember.computed('currentType', function () {
      var types = TYPES.map(function (t) {
        return t.type;
      });
      return types.includes(Ember.get(this, 'currentType'));
    }),
    setModel: function setModel(type) {
      var cachedModel = Ember.get(this, "modelMap.".concat(type));
      var clusterId = Ember.get(this, 'cluster.id');
      var gs = Ember.get(this, 'globalStore');

      if (cachedModel) {
        Ember.set(this, 'model', cachedModel);
        return;
      }

      if (type === 'email') {
        type = 'smtp';
      }

      var configType = "".concat(type, "Config");

      var opt = _defineProperty({
        type: 'notifier',
        name: null,
        description: null,
        clusterId: clusterId
      }, configType, gs.createRecord({
        type: configType
      }));

      var model = Ember.get(this, 'globalStore').createRecord(opt);
      Ember.set(this, 'model', model);
      Ember.set(this, "modelMap.".concat(type), model);
    },
    doneSaving: function doneSaving() {
      Ember.get(this, 'modalService').toggleModal();
    },
    validate: function validate() {
      this._super.apply(this, arguments);

      var errors = Ember.get(this, 'errors') || [];
      var intl = Ember.get(this, 'intl');
      var preError = '"Default Recipient" is required';
      var notifierType = Ember.get(this, 'model.notifierType');

      if (errors.includes(preError)) {
        var afterError = '';

        if (notifierType === 'email') {
          afterError = _constants.default.NOTIFIER_TABLE_LABEL.SMTP;
          errors.splice(errors.findIndex(function (e) {
            return e === preError;
          }), 1, intl.t('validation.required', {
            key: afterError
          }));
        }
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    }
  });

  _exports.default = _default;
});